<script>
/**
 * Stat component -- specify the widget icon, title and value.
 */
import Loader from './loader';

export default {
  components: {
    Loader,
  },
  props: {
    updating: {
      type: Boolean,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <Loader :loading="updating">
    <div class="card mini-stats-wid">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-grow-1">
            <p class="text-muted fw-medium mb-2">{{ title }}</p>
            <h4 class="mb-1" v-if="value < 50">{{ value }}</h4>
            <h4 class="mb-1" v-else>{{ value }}<span class="font-size-14">+</span></h4>
          </div>

          <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
            <span class="avatar-title">
              <i :class="`${icon} font-size-24`"></i>
            </span>
          </div>
        </div>
      </div>
      <!-- end card-body -->
    </div>
  </Loader>
  <!-- end card -->
</template>
